<template>
    <div class="index">
        <ul class="menu">
            <li @click="openMenu('account')">我的资料</li>
            <li @click="openMenu('accountTruthName')">实名认证</li>
            <li @click="openMenu('accountAddress')">我的地址</li>
            <li @click="openMenu('accountOrder')">我的订单</li>
            <li @click="openMenu('accountCart')">我的购物车</li>
            <li @click="openMenu('accountTuijian')">我的推荐</li>
        </ul>
        <div class="main" v-if="!showOrder">
            <div class="title">
                <div class="titleLeft">
                    <span>我的购物车</span> <span class="tips">温馨提示：产品是否购买成功，以最终下单为准，请尽快结算</span>
                </div>
                <div>我的订单</div>
                
            </div>
            <div class="mainBox">
                    <el-table class="cartTable"
                    :data="tableData"
                    ref="multipleTable"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column
                    type="selection"
                    width="55">
                    </el-table-column>
                    <el-table-column
                        prop="storeName"
                        label="商品名称"
                        align="center"
                        width="370">
                        <template slot-scope="scope">
                            <div class="goods">
                                <img :src="scope.row.image" alt="" style="width:100px;height: 100px;display:inline-block">
                                <div>
                                    <span style="">{{scope.row.storeName}}</span> <br>
                                    <span>规格：{{scope.row.suk}}</span>
                                </div>
                                
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        align="center"
                        label="单价"
                        width="100">
                    </el-table-column>
                    <!-- <el-table-column
                        prop="suk"
                        align="center"
                        label="规格"
                       >
                    </el-table-column> -->
                    <el-table-column
                        prop="cartNum"
                        align="center"
                        label="数量" width="200">
                        <template slot-scope="scope">
                            <el-input-number size="small" :min="1" :step="1" @change="handleChange($event,scope.row)" v-model="scope.row.cartNum"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        align="center"
                        label="小计">
                        <template  slot-scope="scope">{{(scope.row.price*1*scope.row.cartNum).toFixed(2)}}</template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete( scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
        
                    </el-table>
                    <div class="tableBar">
                        <div class="tableBarL">
                            <span>继续购物</span>
                            <span>共{{$store.state.cartCount}}件商品，已选择{{chooseNum}}件</span>
                        </div>
                        
                        <div class="tableBarR">
                            <div>合计：<span class="money">{{chooseMoney}}</span> 元</div>
                            <el-button @click="createOrder">去结算</el-button>
                        </div>
                    </div>
            </div>
            
        </div>
        <pay-dialog @finshed='finshed' :show='showPay' :Image='payUrl' :orderNo='orderNo' :price='chooseMoney' />
    </div>
</template>
<script>
import {apiGetCartList,apiGetCartCount,
apiCreateOrder} from '@/api/goods'
import {apiAddressList} from '@/api/account'

import PayDialog from '@/components/PayDialog.vue'
export default {
    name: 'basePAge',
    data(){
        return{
            tableData:[],
            total: 0,
            page: 1,
            limit: 99,
            chooseNum: 0,
            chooseMoney: 0,
            choosedItem: [],
            showOrder: false,
            isOwner: false, //是否到店自提
            note: '',
            orderinfos:{},
            userInfo:{},
            preOrderNo: '',
            storeList: [],//门店列表
            storeId: '',
            isCash: 'weixin',
            phone: '',
            realName: '',
            showPay: false,
            payUrl: '',
            orderNo: '',
            addressList: [],
            addressId: ''
        }
    },
    components:{PayDialog},
    mounted(){
        this.getCartList()
        this.getAddressList()
        this.userInfo = JSON.parse(localStorage.getItem('userInfo')) 
    },
    watch:{
        choosedItem(val){
            console.log(val);
              if(val.length){
                 let a=0;
                let b = 0;
                val.forEach(item => {
                    a+=item.cartNum
                    b+= item.cartNum*(item.price*1)
                });
                this.chooseNum = a
                this.chooseMoney = b.toFixed(2)
            }else{
                this.chooseNum = 0
                this.chooseMoney = 0
            }
        }
    },
    methods:{
        async getAddressList(){
            const result = await apiAddressList()
            if(result.code == 200){
                this.addressList = result.data.list
            }
        },
        finshed(index){
            this.showPay = false
            if(index == 1){
                this.$message.warning({
                    message: '订单支付超时',
                    duration: 3000
                })
            }else{
                   this.$router.replace({name: 'accountOrder'})
                this.$message.success({
                    message: '订单支付完成',
                    duration: 3000
                })
            }
        },
        openMenu(index){
            localStorage.setItem('accountIndex','accountCart')
            this.$router.push({name: index})
        },
        //获取用户信息
        getCartList() {
            apiGetCartList({page: this.page,limit: this.limit}).then(res=>{
                    if(res.code == 200){
                        this.tableData = res.data.list
                        this.total = res.data.total
                        this.$nextTick(()=>{
                            if (this.tableData) {
                            this.tableData.forEach(row => {
                                this.$refs.multipleTable.toggleRowSelection(row);
                            });
                            }
                        })
                        
                    }
            })
        },
        getCartCount(){
            apiGetCartCount({numType: true,type: 'sum'}).then(res=>{
                if(res.code == 200){
                    this.$store.commit('SET_INFO',['cartCount',res.data.count])
                }else{
                    this.$store.commit('SET_INFO',['cartCount',0])
                }
            })
        },
        handleSelectionChange(val){
            console.log(val);
            this.choosedItem = val
            if(val.length){
                 let a=0;
                let b = 0;
                val.forEach(item => {
                    a+=item.cartNum
                    b+= item.cartNum*(item.price*1)
                });
                this.chooseNum = a
                this.chooseMoney = b.toFixed(2)
            }else{
                this.chooseNum = 0
                this.chooseMoney = 0
            }
        },
        handleDelete(id){
            this.$confirm('是否从购物车删除该商品?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.https.post('/api/front/cart/delete?ids='+ id).then(res=>{
                if(res.data.code == 200){
                    this.$message.success('删除成功')
                    this.getCartList()
                    this.getCartCount()
                }else{
                    this.$message.warning(res.message)
                }
            })
            }).catch(() => {
                    
            });
        },
        handleChange(val,item){
            console.log(val,item);
            this.https.post('/api/front/cart/num?id='+item.id+'&number='+ val).then(res=>{
                if(res.data.code === 200){
                    // let arr = this.choosedItem
                    // arr.forEach((items)=>{
                    //     if(items.id == item.id){
                    //         items.cartNum = val
                    //     }
                    // })
                    // this.choosedItem= arr
                    // this.$set(this,'choosedItem',arr)
                    this.getCartList()
                    this.getCartCount()
                }
            })
        },
    
        //创建订单
        createOrder(){
            if(this.choosedItem.length){
                let arr = []
                this.choosedItem.forEach(item=>{
                    arr.push({shoppingCartId: item.id})
                })
                apiCreateOrder({orderDetails: arr,preOrderType: 'shoppingCart'}).then(res=>{
                    if(res.code == 200){
                        this.preOrderNo = res.data.preOrderNo
                        this.$router.replace({path:'/createOrder',query:{
                            orderNo: res.data.preOrderNo,
                            preOrderType: "shoppingCart",
                            params: JSON.stringify({orderDetails: arr,preOrderType: 'shoppingCart'})
                            }})
                    }else{
                         this.$message.warning({
                            message: res.message,
                            duration: 3000
                        })
                    }
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  .menu{
      width: 200px;
      background-color: #fff;
      li{
          line-height: 60px;
          border-bottom: 1px solid #ccc;
          font-size: 16px;
      }
  }
  .main{
      width: 980px;
      background-color: #fff;
      .title{
          height: 70px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          border-bottom: 1px dashed blue;
          .titleLeft{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img{
                  margin-left: 15px;
                  height: 50px!important;
                  width: 100px!important;
              }
              span{
                  margin-left: 15px;
              }
          }
      }
      .goods{
          display: flex;
          align-items: center;
          span{
              line-height: 36px;
          }
      }
  }
}
.mainBox{
    padding: 20px;
    background-color: #fff;
    .tableBar{
        margin-top: 15px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        height: 40px;
        align-items: center;
        padding: 0 20px;
        .tableBarR{
             display: flex;
             align-items: center;
             justify-content: flex-start;
             .money{
                 font-size: 20px;
                 color: orange;
             }
             .el-button{
                 margin-left: 15px;
                 width: 150px;
                 background-color: orange;
                 color: #fff;
                 &:hover{
                     color: #fff!important;
                 }
             }
        }
    }
}
.cartTable{
    border: 1px solid #eee;
}

</style>